import React, { useState } from "react";
import './Gallery.css';

// Import all images from the assets/gallery folder
const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('./assets/gallery', false, /\.(jpeg|jpg|png|gif)$/));

const Gallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    const imageNames = Object.keys(images);

    const openImage = (imageName) => {
        setCurrentImage(imageName);
        setIsOpen(true);
    };

    const closeImage = () => {
        setIsOpen(false);
        setCurrentImage(null);
    };

    const handleNext = () => {
        const currentIndex = imageNames.indexOf(currentImage);
        const nextIndex = (currentIndex + 1) % imageNames.length;
        setCurrentImage(imageNames[nextIndex]);
    };

    const handlePrev = () => {
        const currentIndex = imageNames.indexOf(currentImage);
        const prevIndex = (currentIndex - 1 + imageNames.length) % imageNames.length;
        setCurrentImage(imageNames[prevIndex]);
    };

    return (
        <div className="container-fluid gallery-parent">
            <div className="container gallery-child">
                <h1>GALLERY</h1>
                <div className="row">
                    {imageNames.map((imageName, index) => (
                        <div className="col-6 col-sm-6 col-md-3 pb-3 pl-1" key={index}>
                            <img
                                src={images[imageName]}
                                alt={`${index + 1}`}
                                className=""
                                onClick={() => openImage(imageName)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Lightbox Modal */}
            {isOpen && (
                <div className="lightbox-overlay" onClick={closeImage}>
                    <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
                        <button className="lightbox-btn prev-btn" onClick={handlePrev}>&lt;</button>
                        <img src={images[currentImage]} alt="Current" className="img-fluid" />
                        <button className="lightbox-btn next-btn" onClick={handleNext}>&gt;</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Gallery;
