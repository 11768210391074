import React, { useEffect } from "react";
import 'animate.css';
import WOW from 'wowjs';
import BoardCard from './BoardCard';
import './ZoneBoard.css'
import { Container, Row, Col } from 'react-bootstrap';
import member1 from './assets/images/member-one.png'
import president from './assets/images/president-card.png'
import member2 from './assets/images/member-2.png'
// import member3 from './assets/images/member-3.png'
import member4 from './assets/images/member-4.png'
import member5 from './assets/images/member-5.png'
import member6 from './assets/images/member-6.png'
import member7 from './assets/images/member-7.png'
import member8 from './assets/images/member-8.png'
import member9 from './assets/images/member-9.png'
import member10 from './assets/images/member-10.png'
import member11 from './assets/images/member-11.png'
import member12 from './assets/images/member-12.png'
// import member13 from './assets/images/member-13.png'




function GovBoard() {


    useEffect(() => {
        const wow = new WOW.WOW({ live: false });
        wow.init();
      }, []);
    const members = [
        { name: 'Mr. Deepak Lokhande', title: 'President', image: president },
        { name: 'Mr. Jayesh Pampalia', title: '#####', image:member2 },
        { name: '########', title: '#####', image:member1 },
        // { name: 'Mr. Nagraj Patil', title: '#####', image:member3 },
        { name: '########', title: '#####', image:member4 },
        { name: '########', title: '#####', image:member5 },
        { name: '########', title: '#####', image:member6 },
        { name: 'Mr. Mukesh Ferwani', title: '#####', image:member7 },
        { name: 'Mr. Akhilesh Rathi', title: '#####', image:member8 },
        { name: '########', title: '#####', image:member9 },
        { name: 'Mr. Nagraj Patil', title: '#####', image:member10 },
        { name: '########', title: '#####', image:member11 },
        { name: '########', title: '#####', image:member12 },


        // Add more members as needed
    ];

    return (
        <Container className='zone-container text-center'>
            <h1>JCI 2025 Superheroes</h1>
            <Row>
                {members.map((member, index) => (
                    <Col xs={6} sm={6} lg={3} key={index} className="wow animate__animated animate__fadeIn">
                        <BoardCard name={member.name} title={member.title} image={member.image} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default GovBoard;
