import React from "react";
import './GalleryContainer.css';
// import imageOne from './assets/images/gallery-one.jpeg';
// import imageTwo from './assets/images/gallery-two.jpeg';
// import imageThree from './assets/images/gallery-three.jpeg';
// import imageFour from './assets/images/gallery-four.jpeg';
import imageOne from './assets/images/slide-one.jpg';
import imageTwo from './assets/images/slide-two.jpg';
import imageThree from './assets/images/slide-three.jpg';
import imageFour from './assets/images/slide-four.jpg';

const GalleryContainer = () => {
    return (
        <div className="container-fluid gallery-container py-5">
            <div className="container gallery-content text-center mb-4">
                <h4>GALLERY</h4>
                {/* <p>Our Memorable Memories</p> */}
            </div>
            <div className="container gallery-image">
                <div className="row">
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <img src={imageOne} alt="gallery-image" className="img-fluid rounded shadow-sm" />
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <img src={imageTwo} alt="gallery-image" className="img-fluid rounded shadow-sm" />
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <img src={imageThree} alt="gallery-image" className="img-fluid rounded shadow-sm" />
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <img src={imageFour} alt="gallery-image" className="img-fluid rounded shadow-sm" />
                    </div>
                    <div className="viewbtn col-12 mt-3 text-center border-rounded">
                        <a href="Gallery" className="btn-link">View More</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GalleryContainer;
