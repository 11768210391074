import React from "react";
import './TagContainer.css';


const TagContainer = () => {
    return (
        <div className="container-fluid parent-tag-container">
            <div className="container child-tag-container">
                <h4 className="text-dark">OUR TAGLINE</h4>
                <p>Developing Leaders for a changing world.</p>
            </div>
        </div>
    );
}


export default TagContainer;