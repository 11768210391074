import React from 'react';
import './BoardCard.css'; // Import the CSS file

function BoardCard({ name, title, image }) {
    return (
        <div className="profile-card">
            <div className="image-frame">
                {image ? (
                    <img src={image} alt={`${name}'s profile`} className="profile-image" />
                ) : (
                    <div className="image-placeholder"></div>
                )}
            </div>
            <div className="text-frame">
                <h2 className="heading">{name}</h2>
                <p className="paragraph">{title}</p>
            </div>
        </div>
    );
}

export default BoardCard;
