import React from "react";
import './ProgramsContainer.css';

const ProgramsContainer = () => {
    return (
        <div className="container-fluid programs-parent-container py-5">
            <div className="container programs-child-container text-center text-md-start">
                <h3 className="mb-4">Check out our Upcoming Programs</h3>
                <div className="row align-items-center mb-2">
                    <div className="col-12 col-md-8">
                        <h4></h4>
                    </div>
                </div>
                <div className="row two">
                    <div className="col-12 col-sm-6 col-md-4 mb-4 program-content text-start">
                        <h5>JCI AMRAVATI</h5>
                        <img src="/assets/images/landscape-image.jpg" alt="Program 1" className="img-fluid rounded program-image" />
                        <h6>Location: #####</h6>
                        <h6>Time: 00:00pm - 11:00am</h6>
                        <h6>Date: 00 ### 0000 - 00 ### 0000</h6>
                        <div className="viewbtn mt-1 text-start border-rounded">
                        <a href="/src/components/Gallery.js" className="btn-link">View More</a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-4 program-content text-start">
                        <h5>JCI AMRAVATI</h5>
                        <img src="/assets/images/landscape-image.jpg" alt="Program 1" className="img-fluid rounded program-image" />
                        <h6>Location: #####</h6>
                        <h6>Time: 00:00pm - 11:00am</h6>
                        <h6>Date: 00 ### 0000 - 00 ### 0000</h6>
                        <div className="viewbtn mt-1 text-start border-rounded">
                        <a href="/src/components/Gallery.js" className="btn-link">View More</a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-4 program-content text-start">
                        <h5>JCI AMRAVATI</h5>
                        <img src="/assets/images/landscape-image.jpg" alt="Program 1" className="img-fluid rounded program-image" />
                        <h6>Location: #####</h6>
                        <h6>Time: 00:00pm - 11:00am</h6>
                        <h6>Date: 00 ### 0000 - 00 ### 0000</h6>
                        <div className="viewbtn mt-1 text-start border-rounded">
                        <a href="/src/components/Gallery.js" className="btn-link">View More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgramsContainer;
