import { Carousel } from 'react-bootstrap';
import slider1 from './assets/images/slide-one.jpg';
import slider2 from './assets/images/slide-two.jpg';
import slider3 from './assets/images/slide-three.jpg';
import slider4 from './assets/images/slide-four.jpg';

import './CarouselContainer.css';

function CarouselContainer() {
  return (
    <div className="carousel-slide">


    <Carousel slide={false} interval={2000}>
      <Carousel.Item>
        <img className='d-block w-100' src={slider1} alt="" />
        {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img className='d-block w-100' src={slider2} alt="" />
        {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img className='d-block w-100' src={slider3} alt="" />
        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img className='d-block w-100' src={slider4} alt="" />
        {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
    </div>
  );
}

export default CarouselContainer;
