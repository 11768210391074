import React from 'react';
import './Footer.css';

import logo from './assets/images/JCI.png';
import bizologo from './assets/images/bizonancelogo.png';

const Footer = () => {
  return (
    <footer className="footer py-4 text-light">
      <div className="container">
        {/* Logo, Location, and Mail Us */}
        <div className="row text-center mb-1 first-row">
          <div className="col-12 col-md-4 d-flex align-items-start flex-column">
            <img src={logo} alt="Logo" className="footer-logo" />
            {/* <div className="text-center follow-section">
              <h5 className="mt-4 mb-2">Follow Us</h5>
              <ul className="list-unstyled d-flex justify-content-center follow-icons">
                <li className="mr-3">
                  <a href="https://facebook.com/bizonance" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/bizonance" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="col-12 col-md-4 mb-2 mt-3">
          <div className="text-start follow-section">
              <h5 className="mt-0 mb-2">Follow Us</h5>
              <ul className="list-unstyled d-flex follow-icons">
                <li className="mr-3">
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className='mr-3'>
                  <a href="h/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="h/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>


          <div className="col-12 col-md-4 mb-2">
            <div className="quick-section">
            <h5 className="mb-2 text-center">Quick Links</h5>
            <div className="row">
              <div className="col-12 quick-link mb-3">
                <ul className="list-unstyled text-center">
                  <li><a href="/about">About us</a></li>
                  <li><a href="/services">Privacy and Policy</a></li>
                  <li><a href="/contact">Terms of Service</a></li>
                  <li><a href="/contact">Return & Refund</a></li>
                  <li><a href="/contact">Payment</a></li>
                </ul>
              </div>
              {/* <div className="col-6">
                <ul className="list-unstyled text-start">

                  <li><a href="/contact">Members Portal Login</a></li>
                  <li><a href="/contact">JCI Amravati Foundation</a></li>
                  <li><a href="/contact">Senior Members Association</a></li>
                </ul>
              </div> */}
            </div>
            </div>
          </div>


        {/* <hr className="footer-breaker mb-2" /> */}


        {/* <div className="loc-mail col-md-4"> */}
            <div className="icon-box col-md-4">
                <div className="footer-icons">
                  <img src="/assets/images/location.png" alt="" className='footer-icon'/>
                </div>
                <p>Amravati, Maharashtra, India</p>
            </div>
            <div className="icon-box col-md-4">
              <div className="footer-icons">
                <img src="/assets/images/mail.png" alt="" className='footer-icon'/>
              </div>
                <p><a href="/">support@jciamravati.in</a></p>
            </div>
            <div className="icon-box col-md-4">
              <div className="footer-icons">
                <img src="/assets/images/telephone.png" alt="" className='footer-icon'/>
              </div>
                <p>9011193541</p>
            </div>
        {/* </div> */}
          

        </div>

        {/* Divider */}
        <hr className="footer-breaker mb-2" />

        {/* Links and Download Section */}
        {/* <div className="row text-md-left second-row">
          <div className="col-12 col-md-6 mb-2 text-center">
            <h5 className="mt-4 mb-2">Follow Us</h5>
            <ul className="list-unstyled d-flex justify-content-center follow-icons">
              <li className="mr-3">
                <a href="https://facebook.com/bizonance" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://instagram.com/bizonance" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          

          <div className="col-12 col-md-4 mb-2 text-center">
            <h5 className="mb-2">Download App</h5>
            <div className="d-flex flex-column align-items-center">
              <a href="https://apps.apple.com/us/app/bizonance" target="_blank" rel="noopener noreferrer" className="mb-2">
                <img src="/assets/images/appstore.png" alt="Download on the App Store" className="footer-download-img" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.bizonance" target="_blank" rel="noopener noreferrer">
                <img src="/assets/images/google-play.png" alt="Get it on Google Play" className="footer-download-img" />
              </a>
            </div>
          </div>
        </div> */}

        {/* Copyright */}
        <div className="text-center">
          <p>JCI Amravati  &#169; Copyright 2025 </p>
           <p className='bizotext' >Designed and Managed by</p>  
          <a href="http://bizonance.in"><img src={bizologo} alt="" className='bizologo'/></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
