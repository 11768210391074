import React, { useState, useEffect, useRef } from 'react';
import './TopContainer.css';
import logo from './assets/images/JCI.png';

import bizologo from './assets/images/bizonancelogo.png';


const TopContainer = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [dropdownsOpen, setDropdownsOpen] = useState({
    team: false,
    event: false,
    program: false,
  });

  const menuRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const toggleDropdown = (dropdown) => {
    setDropdownsOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const handleHover = (dropdown, isHovered) => {
    if (!isMobile) {
      setDropdownsOpen((prev) => ({
        ...prev,
        [dropdown]: isHovered,
      }));
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="top-container">

      <a href="/"><img src={logo} alt="Logo" className="main-logo" /></a>


      <nav className="navbar navbar-expand-lg navbar-light bg-light" ref={menuRef}>
        <div className="container-fluid">
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <img src={logo} alt="Sidebar-Logo" className="side-bar-logo" />
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">Home</a>
              </li>
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('about')}
                onMouseEnter={() => handleHover('about', true)}
                onMouseLeave={() => handleHover('about', false)}
              >
                <a className="nav-link dropdown-toggle" href="#" id="aboutDropdown">
                  About Us
                </a>
                {dropdownsOpen.about && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/About">About JCI</a></li>
                    <li><a className="dropdown-item" href="/AboutJCI">JCI Amravati</a></li>
                  </ul>
                )}
              </li>
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('team')}
                onMouseEnter={() => handleHover('team', true)}
                onMouseLeave={() => handleHover('team', false)}
              >
                <a className="nav-link dropdown-toggle" href="#" id="teamDropdown">
                  Team
                </a>
                {dropdownsOpen.team && (
                  <ul className="dropdown-menu show">
                    {/* <li><a className="dropdown-item" href="/src/components/GovBoard.js">National Governing Board</a></li>
                    <li><a className="dropdown-item" href="/src/components/ZoneBoard.js">National Committees</a></li> */}

                    <li><a className="dropdown-item" href="/">Past Presidents</a></li>
                    <li><a className="dropdown-item" href="/Members 2025">JCI 2025 Superheroes</a></li>
                    <li><a className="dropdown-item" href="">JCI Members</a></li>
                  </ul>
                )}
              </li>
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('event')}
                onMouseEnter={() => handleHover('event', true)}
                onMouseLeave={() => handleHover('event', false)}
              >
                <a className="nav-link dropdown-toggle" id="eventDropdown">
                  Event
                </a>
                {dropdownsOpen.event && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/">National Events</a></li>
                    <li><a className="dropdown-item" href="/">Zone Events</a></li>
                    <li><a className="dropdown-item" href="/">LO Events</a></li>
                    <li><a className="dropdown-item" href="/">Awards Bidding Portal</a></li>
                  </ul>
                )}
              </li>
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('program')}
                onMouseEnter={() => handleHover('program', true)}
                onMouseLeave={() => handleHover('program', false)}
              >
                <a className="nav-link dropdown-toggle" href="#" id="programDropdown">
                  Program
                </a>
                {dropdownsOpen.program && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/">National Program</a></li>
                    <li><a className="dropdown-item" href="/">Community Development Reporting</a></li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Gallery">Gallery</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">Download</a>
              </li>
            </ul>

            <div className="sidebar-icons">
              <ul className="list-unstyled d-flex flex-column">
                <div className="member-login">
                  <span><a href="/Form">Member Registration</a></span>
                </div>

                <div className="icons-div">
                  <li><a href="/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a href="/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
                  <li><a href="/" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a></li>
                </div>


                <div className="bizo-content text-center mt-5">
                  <p>Managed by</p>
                  <a href="http://bizonance.in"><img src={bizologo} alt="" style={{height: "50px", marginTop: "-10px"}} /></a>
                </div>
              </ul>
            </div>


          </div>

          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
          >
            <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </button>
        </div>

      </nav>

      <div className="social-icons">
          <span><a href="/Form">Member Registration</a></span>
          <a href="/" target="_blank" className="mx-2"><i className="fab fa-facebook-f"></i></a>
          <a href="/" target="_blank" className="mx-2"><i className="fab fa-instagram"></i></a>
          <a href="/" target="_blank" className="mx-2"><i className="fab fa-youtube"></i></a>
      </div>


    </div>
  );
};

export default TopContainer;
