import React from "react";
import './Form.css';


const Form = () => {
    return (
        <div className="container-fluid form-parent">
            <div className="form-child p-0">

                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf-uHEhTMy9A5lxu_SVq7-ELSdDhBWZSNRlgrrhnjcMpx8N9Q/viewform?embedded=true" width="1400" height="2200" frameborder="0" marginheight="0" marginwidth="0" title="form"></iframe>

            </div>
        </div>
    );
};


export default Form;