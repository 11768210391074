import React from 'react';
import BoardCard from './BoardCard';
import './ZoneBoard.css'
import { Container, Row, Col } from 'react-bootstrap';
import square from './assets/images/square-image.jpg'

function ZoneBoard() {
    const members = [
        { name: '##########', title: 'President', image: square },
        { name: '##########', title: 'IPNP', image:square },
        { name: '##########', title: 'Secretary General', image:square },
        { name: '##########', title: 'General Legal Counsel', image:square },
        // Add more members as needed
    ];

    return (
        <Container className='zone-container'>
            <Row>
                {members.map((member, index) => (
                    <Col xs={12} sm={6} md={3} key={index}>
                        <BoardCard name={member.name} title={member.title} image={member.image} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default ZoneBoard;
